import { EventEmitter, Injectable } from '@angular/core';

import { StorageService } from './storage.service';

import { DadosCalculo } from '../types/orcamento/dados-calculo';

@Injectable()
export class ConfigService {

  static storageDadosCalculo = 'DadosCalculo';

  private clienteChanged: EventEmitter<string> = new EventEmitter();

  constructor(
    private storage: StorageService
  ) { }

  get dadosCalculo(): DadosCalculo {
    return this.storage.getStorage(ConfigService.storageDadosCalculo) || <DadosCalculo>{};
  }

  set dadosCalculo(value: DadosCalculo) {
    const save = <DadosCalculo>{ ...value };
    this.storage.setStorage<DadosCalculo>(ConfigService.storageDadosCalculo, save);
    this.emitClienteChangedEvent();
  }

  emitClienteChangedEvent() {
    this.clienteChanged.emit();
  }

  getClienteChangedEmitter() {
    return this.clienteChanged;
  }

}
