import { Injectable } from '@angular/core';

import { StorageService } from './storage.service';
import { IAuth } from '../types/auth/auth';
import { tryParse } from './util.service';

@Injectable()
export class AuthorizationService {
  static storage = 'Auth';

  constructor(private storage: StorageService) {
  }

  get authorization(): IAuth {
    return this.storage.getStorage(AuthorizationService.storage) || <IAuth>{};
  }

  set authorization(value: IAuth) {
    value.roles = tryParse(<string>value.roles);

    const save = <IAuth>{
      ...this.authorization,
      ...value
    };

    this.storage.setStorage<IAuth>(AuthorizationService.storage, save);
  }

  get identification(): string {
    return this.authorization.identification;
  }

  get name(): string {
    return this.authorization.name;
  }

  set identification(value: string) {
    const save = <IAuth>{
      ...this.authorization,
      identification: value
    };

    this.storage.setStorage<IAuth>(AuthorizationService.storage, save);
  }

  get token(): string {
    return this.authorization.access_token;
  }

  get roles(): string[] {
    return <string[]>this.authorization.roles || [];
  }

  get userId(): string {
    return this.authorization.userId;
  }
}
