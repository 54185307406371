import { Component } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

import { AnalyticsService } from './core/services/analytics.service';

@Component({
  selector: 'orcc-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  constructor(
    public router: Router,
    public analyticsService: AnalyticsService) {   
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd){
        analyticsService.config(event);
      }
    })
  }

  public onActivate() {
    setTimeout(() => window.scrollTo(0, 0));
  }
}
