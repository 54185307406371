import { Observable, throwError as observableThrowError} from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { IAuth } from '../types/auth/auth';
import { tap } from 'rxjs/operators';

import { StorageService } from '../services/storage.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private storage: StorageService
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const auth: IAuth = this.storage.getStorage('Auth');
    if (auth) {
      req = req.clone({
        setHeaders: {
          Authorization: `Bearer ${auth.access_token}`
        }
      });
    }

    return next
      .handle(req)
      .pipe(
        tap(
          () => {
          },
          (err: any) => {
            if (err instanceof HttpErrorResponse) {
              if (err.status === 401) {
                console.log('Error Occurred');
                console.log(err);
                return observableThrowError(err);
              }
              return err;
            }
            return err;
          }
        )
      );
  }
}
