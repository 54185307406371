import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ConfigService } from '../services/config.service';

import { environment } from '../../../environments/environment';
import { ListaProdutos } from '../types/produto/lista-produtos';
import { PaginaInfo } from '../types/paginacao/pagina-info';
import { Produto } from '../types/produto/produto';
import { Categoria } from '../types/categoria/categoria';
import { SubCategoria } from '../types/categoria/sub-categoria';
import { ItemCarrinho } from '../types/carrinho/item-carrinho';


export interface ProdutoFiltro {
  TextoBusca: string;
  Categorias: string;
  SubCategorias: string;
  PaginaInfo: PaginaInfo;
  ValorMinimo: string;
  ValorMaximo: string;
  Ordenar: string;
}

@Injectable()
export class CatalogoService {

  private filtro: ProdutoFiltro;

  private textoBuscaChange: EventEmitter<string> = new EventEmitter();

  constructor(
    private http: HttpClient,
    private config: ConfigService
  ) {
    const filtro: ProdutoFiltro = <ProdutoFiltro>{};
    const paginaInfo: PaginaInfo = <PaginaInfo>{};
    paginaInfo.Pagina = 1;
    paginaInfo.LinhasPorPagina = 20;
    filtro.PaginaInfo = paginaInfo;
    this.filtro = filtro;
   }

  pesquisarProdutos(filtro: ProdutoFiltro = null): Observable<ListaProdutos> {
    const filtrar: ProdutoFiltro = filtro || this.filtro;
    let params = new HttpParams();

    let categorias = '';
    const categoriasValidacao = (filtrar.Categorias || '').split(',');
    categoriasValidacao.forEach(value => {
      if (/^[\d]{1,3}$/.test(value)) {
        categorias += ',' + value;
      }
    });
    filtrar.Categorias = categorias;

    let subCategorias = '';
    const subCategoriasValidacao = (filtrar.SubCategorias || '').split(',');
    subCategoriasValidacao.forEach(value => {
      if (/^[\d]{1,3}$/.test(value)) {
        subCategorias += ',' + value;
      }
    });
    filtrar.SubCategorias = subCategorias;

    params = params.set('idCliente', this.config.dadosCalculo.IdCliente.toString());
    params = params.set('pagina', filtrar.PaginaInfo.Pagina.toString());
    params = params.set('linhasPorPagina', filtrar.PaginaInfo.LinhasPorPagina.toString());
    params = params.set('textoBusca', encodeURIComponent(filtrar.TextoBusca || ''));
    params = params.set('categorias', filtrar.Categorias || '');
    params = params.set('subCategorias', filtrar.SubCategorias || '');
    params = params.set('valorMaximo', filtrar.ValorMaximo || '');
    params = params.set('valorMinimo', filtrar.ValorMinimo || '');
    params = params.set('ordenar', filtrar.Ordenar || '');

    return this.http.get(`${environment.api}/Catalogo/ObterProdutosParaClienteLogado`, { params: params })
      .pipe(map((res: ListaProdutos) => res));
  }

  buscarProdutoPorId(idProduto: number): Observable<Produto> {
    let params = new HttpParams();

    params = params.set('idCliente', this.config.dadosCalculo.IdCliente.toString());
    params = params.set('idproduto', idProduto.toString());

    return this.http.get(`${environment.api}/Catalogo/ObterProdutoParaClienteLogado`, { params: params })
      .pipe(map((res: Produto) => res));
  }

  buscarCategorias(): Observable<Categoria[]> {
    let params = new HttpParams();

    params = params.set('idCliente', this.config.dadosCalculo.IdCliente.toString());

    return this.http.get(`${environment.api}/Catalogo/ObterCategorias`, { params: params })
      .pipe(map((res: Categoria[]) => res));
  }

  buscarSubCategorias(categorias: string): Observable<SubCategoria[]> {
    let params = new HttpParams();

    params = params.set('idCliente', this.config.dadosCalculo.IdCliente.toString());
    params = params.set('categorias', categorias || '');

    return this.http.get(`${environment.api}/Catalogo/ObterSubCategorias`, { params })
      .pipe(map((res: SubCategoria[]) => res));
  }

  public importar(arquivo: File): Observable<ItemCarrinho[]> {
    let params = new HttpParams();

    params = params.set('idCliente', this.config.dadosCalculo.IdCliente.toString());

    const formData = new FormData();
    formData.append('file', arquivo, arquivo.name);
    return this.http.post(`${environment.api}/Catalogo/Importar`, formData, { params: params })
      .pipe(map((res: any) => {
        return res as ItemCarrinho[];
      }));
  }

  produtosRequeridos(idCatalogoProdutoCategoria: number): Observable<Produto[]> {
    let params = new HttpParams();

    params = params.set('idCliente', this.config.dadosCalculo.IdCliente.toString());
    params = params.set('idCatalogoProdutoCategoria', idCatalogoProdutoCategoria.toString());

    return this.http.get(`${environment.api}/Catalogo/ObterProdutosRequeridos`, { params: params })
      .pipe(map((res: Produto[]) => res));
  }

  obterFiltroProduto(): ProdutoFiltro {
    return this.filtro;
  }

  redefinirFiltroProduto(value: ProdutoFiltro) {
    this.filtro = value;
  }

  emitTextoBuscaChangeEvent(texto: string) {
    this.textoBuscaChange.emit(texto);
  }

  getTextoBuscaChangeEmitter() {
    return this.textoBuscaChange;
  }

}
