import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ConfigService } from '../services/config.service';

import { environment } from '../../../environments/environment';
import { SituacaoOrcamento } from '../types/situacao-orcamento/situacao-orcamento';

@Injectable()
export class SituacaoOrcamentoService {

  constructor(
    private http: HttpClient,
    private config: ConfigService
  ) {}

  listar(): Observable<SituacaoOrcamento[]> {
    let params = new HttpParams();

    params = params.set('idCliente', this.config.dadosCalculo.IdCliente.toString());

    return this.http.get(`${environment.api}/SituacoesOrcamento/ObterSituacoesAtivas`, { params: params })
      .pipe(map((res: SituacaoOrcamento[]) => res));
  }

}
