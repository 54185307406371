import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { Cliente } from '../types/cliente/cliente';

@Injectable()
export class ClienteService {

  constructor(
    private http: HttpClient
  ) {}

  listar(): Observable<Cliente[]> {
    return this.http.get(`${environment.api}/Clientes/ObterClientesParaUsuarioLogado`)
      .pipe(map((res: Cliente[]) => res));
  }

}
