import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import * as _ from 'lodash';
import swal from 'sweetalert2';

import { StorageService } from './storage.service';

import { ItemCarrinho } from '../types/carrinho/item-carrinho';

@Injectable()
export class CarrinhoService {
  static storage = 'Carrinho';
  static storageItensARemover = 'ItensARemover';

  constructor(
    private storage: StorageService,
    private snackBar: MatSnackBar
  ) {}

  limpar() {
    this.itens = <ItemCarrinho[]>[];
  }

  limparItensARemover() {
    this.itensARemover = <ItemCarrinho[]>[];
  }

  adicionarItem(item: ItemCarrinho, mostraMensagem: boolean = true, reorganizarNumeroLinha: boolean = false) {
    if ((item.Quantidade % item.Produto.MultiploVenda) != 0){
      swal.fire({
        title: 'Atenção!',
        text: `A quantidade não respeita o múltiplo de vendas de ${item.Produto.MultiploVenda}.`,
        type: 'warning'
      }).then();
      return;
    }

    const carrinho = this.itens;
    if (reorganizarNumeroLinha) {
      if (carrinho.length) {
        for (let i = 0; i < carrinho.length; i++) {
          carrinho[i].NumeroLinha = i + 1;
        }
      }
    }
    if (!carrinho.length) {
      item.NumeroLinha = 1;
    } else {
      item.NumeroLinha = _.maxBy(carrinho, function(o) { return o.NumeroLinha; }).NumeroLinha + 1;
    }

    item.DataCriacao = new Date;
    item.DataAlteracao = new Date;

    carrinho.push(item);
    if (mostraMensagem) {
      this.snackBar.open(`Você adicionou o item ${item.Produto.Descricao}`);
    }
    this.itens = carrinho;
  }

  editarItem(item: ItemCarrinho) {
    if ((item.Quantidade % item.Produto.MultiploVenda) != 0){
      swal.fire({
        title: 'Atenção!',
        text: `A quantidade não respeita o múltiplo de vendas de ${item.Produto.MultiploVenda}.`,
        type: 'warning'
      }).then();
      return;
    }

    const carrinho = this.itens;
    const itemCarrinho = carrinho.find(p => p.Produto.IdProduto === item.Produto.IdProduto
      && p.Agrupamento === item.Agrupamento && p.NumeroLinha === item.NumeroLinha);
    itemCarrinho.Quantidade = item.Quantidade;

    this.snackBar.open(`Você alterou o item ${item.Produto.Descricao}`);
    this.itens = carrinho;
  }

  itemAssociadoAoAgrupamento(item: ItemCarrinho, nomeAgrupamento: string) {
    return _.filter(this.itens, p => p.Produto.IdProduto === item.Produto.IdProduto
      && p.Agrupamento === nomeAgrupamento && p.NumeroLinha === item.NumeroLinha).length > 0;
  }

  obterItem(item: ItemCarrinho) {
    return _.find(this.itens, p => p.Produto.IdProduto === item.Produto.IdProduto
      && p.Agrupamento === item.Agrupamento && p.NumeroLinha === item.NumeroLinha);
  }

  removerItem(item: ItemCarrinho, mostraMensagem: boolean = true) {
    let carrinho = this.itens;

    let itemARemover = _.filter(carrinho, p => (p.Produto.IdProduto === item.Produto.IdProduto
      && p.Agrupamento === item.Agrupamento && p.NumeroLinha === item.NumeroLinha));

    carrinho = _.filter(carrinho, p => !(p.Produto.IdProduto === item.Produto.IdProduto
      && p.Agrupamento === item.Agrupamento && p.NumeroLinha === item.NumeroLinha));

    if (!carrinho.length) {
      item.NumeroLinha = 1;
    } else {
      for (let i = 0; i < carrinho.length; i++) {
        carrinho[i].NumeroLinha = i + 1;
      }
    }

    this.itens = carrinho;
    this.itensARemover = itemARemover;

    if (mostraMensagem) {
      this.snackBar.open(`Você removeu o item ${item.Produto.Descricao}`);
    }
  }

  get quantidade(): number {
    return this.itens
      .map(item => item.Quantidade)
      .reduce((prev, value) => prev + value, 0);
  }

  get itens(): ItemCarrinho[] {
    return this.storage.getStorage(CarrinhoService.storage, 'localStorage') || <ItemCarrinho[]>[];
  }

  set itens(value: ItemCarrinho[]) {
    const save = <ItemCarrinho[]>[ ...value ];
    this.storage.setStorage<ItemCarrinho[]>(CarrinhoService.storage, save, 'localStorage');
  }

  get itensARemover(): ItemCarrinho[] {
    return this.storage.getStorage(CarrinhoService.storageItensARemover, 'localStorage') || <ItemCarrinho[]>[];
  }

  set itensARemover(value: ItemCarrinho[]) {
    const save = <ItemCarrinho[]>[ ...value ];
    this.storage.setStorage<ItemCarrinho[]>(CarrinhoService.storageItensARemover, save, 'localStorage');
  }
}
