import { Title } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule, LOCALE_ID } from '@angular/core';
import { CommonModule } from '@angular/common';
import { registerLocaleData } from '@angular/common';

import localePt from '@angular/common/locales/pt';
import localeFrExtra from '@angular/common/locales/extra/pt';
registerLocaleData(localePt, 'pt', localeFrExtra);

import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule, MatDatepickerIntl } from '@angular/material/datepicker';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { MAT_SNACK_BAR_DEFAULT_OPTIONS } from '@angular/material/snack-bar';
import { MatSnackBarModule } from '@angular/material/snack-bar';

import { AuthInterceptor } from './interceptors/auth.interceptor';

import { AuthGuard } from './guards/auth.guard';
import { HasAuthGuard } from './guards/has-auth.guard';

import { CustomMatPaginatorIntl } from './types/paginacao/custom-mat-paginator-intl';

import { AuthorizationService } from './services/authorization.service';
import { AnalyticsService } from './services/analytics.service';
import { LoginService } from './services/login.service';
import { StorageService } from './services/storage.service';
import { CatalogoService } from './services/catalogo.service';
import { ErrorHandlerService } from './services/error-handler.service';
import { CarrinhoService } from './services/carrinho.service';
import { ConteudoService } from './services/conteudo.service';
import { CondicaoPagamentoService } from './services/condicao-pagamento.service';
import { ContratoService } from './services/contrato.service';
import { OrcamentoService } from './services/orcamento.service';
import { AgrupamentoService } from './services/agrupamento.service';
import { SituacaoOrcamentoService } from './services/situacao-orcamento.service';
import { FaleConoscoService } from './services/fale-conosco.service';
import { ClienteService } from './services/cliente.service';
import { ConfigService } from './services/config.service';

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    RouterModule,
    MatSnackBarModule,
    MatDatepickerModule,
    MatNativeDateModule
  ],
  declarations: [
  ],
  exports: [
  ],
  providers: [
    Title,
    AuthorizationService,
    AnalyticsService,
    LoginService,
    StorageService,
    CatalogoService,
    CarrinhoService,
    ErrorHandlerService,
    ConteudoService,
    CondicaoPagamentoService,
    ContratoService,
    OrcamentoService,
    AgrupamentoService,
    SituacaoOrcamentoService,
    FaleConoscoService,
    ClienteService,
    ConfigService,
    AuthGuard,
    HasAuthGuard,
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: LOCALE_ID, useValue: 'pt-BR' },
    { provide: MatPaginatorIntl, useClass: CustomMatPaginatorIntl },
    { provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: { duration: 2500 } },
    { provide: MatDatepickerIntl, useClass: MatDatepickerIntl }
  ]
})
export class CoreModule { }
