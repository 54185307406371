import { Injectable } from '@angular/core';
import { Info } from '../types/login/info';
import { LoginService } from './login.service';
import { AuthorizationService } from './authorization.service';
import { NavigationEnd } from '@angular/router';

import { environment } from '../../../environments/environment';

declare let gtag: Function;

@Injectable()
export class AnalyticsService {
  constructor(
    private loginService: LoginService,
    private authorizationService: AuthorizationService) {
  }

  public config(event: NavigationEnd) {
    this.setUserId();

    gtag('config', environment.gaMeasurementId, {
      'page_path': event.urlAfterRedirects
    });
  }

  public setUserId() {
    if (!this.authorizationService.identification)
      return;

    this.loginService.resgataInfos(this.authorizationService.identification)
      .subscribe((res: Info ) => {
        this.authorizationService.authorization = {
          ...this.authorizationService.authorization,
          name: res.Nome
        };
        gtag('set', {'user_id': res.IdUsuario });
      });
  }
}
