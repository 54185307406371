import { Injectable } from '@angular/core';
import { tryParse } from './util.service';

@Injectable()
export class StorageService {
  protected prefix = 'Orcamentacao';

  constructor() {
  }

  public getStorage<T>(storage: string, type: string = 'sessionStorage'): T {
    return tryParse(window[type].getItem(`${this.prefix}.${storage}`));
  }

  public hasStorage(storage: string, type: string = 'sessionStorage'): boolean {
    return !!window[type].getItem(`${this.prefix}.${storage}`);
  }

  public setStorage<T>(storage: string, data: T, type: string = 'sessionStorage'): void {
    window[type].setItem(`${this.prefix}.${storage}`, JSON.stringify(data));
  }

  public delStorage(storage: string, type: string = 'sessionStorage'): void {
    window[type].removeItem(`${this.prefix}.${storage}`);
  }

  public clearStorage(keysIgnore?: string[], type: string = 'sessionStorage'): void {
    keysIgnore = (keysIgnore || []).map((key) => `${this.prefix}.${key}`);

    Object.keys(window[type]).map(function (chave) {
      if (keysIgnore.indexOf(chave) === -1) {
        window[type].removeItem(chave);
      }
    });
  }
}
