import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { Login } from '../types/login/login';
import { IAuth } from '../types/auth/auth';

import { AuthorizationService } from './authorization.service';
import { AlterarSenha } from '../types/login/alterar-senha';

@Injectable()
export class LoginService {

  constructor(
    private http: HttpClient,
    private auth: AuthorizationService
  ) { }

  token(login: Login): Observable<IAuth> {
    const body = new HttpParams()
      .set('userName', login.usuario)
      .set('password', login.senha)
      .set('grant_type', 'password');

    return this.http.post(environment.token, body)
      .pipe(map((res: IAuth) => {
        this.auth.authorization = res;
        this.auth.identification = login.usuario;
        return res;
      }));
  }

  esqueceuSenha(login: Login): Observable<string> {
    const body = new HttpParams()
      .set('email', login.usuario);

    return this.http
      .post(`${environment.api}/Usuarios/RecuperarSenha`, body)
      .pipe(map((data: string) => data));
  }

  resgataInfos(email: string) {
    let params = new HttpParams();

    params = params.set('email', email);

    return this.http.get(`${environment.api}/Usuarios/RecuperarInfos`, { params });
  }

  recuperar(parametros: any) {
    return this.http
      .post(`${environment.api}/Account/ResetPassword`, parametros)
      .pipe(map((data: string) => JSON.parse(data)));
  }

  validaToken(parametros: any) {
    return this.http
      .post(`${environment.api}/Account/ValidateUserToken`, parametros);
  }

  temPermissao(permissao: string) {
    const roles = this.auth.roles;

    if (roles.length) {
      return roles.indexOf(permissao) > -1;
    }

    return false;
  }

  temQualquerPermissao(roles: string) {
    for (const role of roles) {
      if (this.temPermissao(role)) {
        return true;
      }
    }

    return false;
  }

  alteraSenha(alterarSenha: AlterarSenha) : Observable<any> {
    const body = new HttpParams()
      .set('Email', alterarSenha.email)
      .set('SenhaAtual', alterarSenha.senhaAtual)
      .set('NovaSenha', alterarSenha.novaSenha)
      .set('ConfirmacaoSenha', alterarSenha.confirmacaoSenha);

    return this.http.post(`${environment.api}/Usuarios/AlteraSenha`, body, { observe: 'response' })
      .pipe(map((res: any) => res));
  }
}
