import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import swal from 'sweetalert2';

import { LoginService } from '../services/login.service';
import { StorageService } from '../services/storage.service';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(
    private auth: LoginService,
    private storage: StorageService,
    private router: Router
  ) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {

    if (!this.storage.getStorage('Auth')) {
      this.router.navigate(['login']);
      return false;
    } else if (next.data.roles && !this.auth.temQualquerPermissao(next.data.roles)) {
      swal.fire({
        title: 'Atenção!',
        text: 'Você não possui permissão para acessar essa página!',
        type: 'warning'
      }).then();

      return false;
    }

    return true;
  }
}
