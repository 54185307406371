import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { FaleConosco } from '../types/fale-conosco/fale-conosco';

@Injectable()
export class FaleConoscoService {
  constructor(
    private http: HttpClient
  ) {
  }

  enviaEmailFaleConosco(request: FaleConosco): Observable<any> {
    const body = new HttpParams()
      .set('Assunto', request.assunto)
      .set('Mensagem', request.mensagem)
      .set('EmailUsuario', request.emailUsuario);

    return this.http.post(`${environment.api}/FaleConosco/`, body, { observe: 'response' })
      .pipe(map((res: any) => res));
  }
}
