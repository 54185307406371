import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuard } from './core/guards/auth.guard';
import { HasAuthGuard } from './core/guards/has-auth.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login-page/login-page.module').then(m => m.LoginPageModule),
    canActivate: [HasAuthGuard]
  },
  {
    path: 'home',
    loadChildren: () => import('./pages/home-page/home-page.module').then(m => m.HomePageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'portifolio/:idCategoria',
    loadChildren: () => import('./pages/portifolio/portifolio.module').then(m => m.PortifolioModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'busca-de-produto',
    loadChildren: () => import('./pages/busca-produto-page/busca-produto-page.module').then(m => m.BuscaProdutoPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'produto/:id',
    loadChildren: () => import('./pages/produto-page/produto-page.module').then(m => m.ProdutoPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'produto/:id/:item/:agrpto',
    loadChildren: () => import('./pages/produto-page/produto-page.module').then(m => m.ProdutoPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'area-do-cliente',
    loadChildren: () => import('./pages/area-cliente-page/area-cliente-page.module').then(m => m.AreaClientePageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'fale-conosco',
    loadChildren: () => import('./pages/fale-conosco-page/fale-conosco-page.module').then(m => m.FaleConoscoPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'perfil',
    loadChildren: () => import('./pages/perfil-page/perfil-page.module').then(m => m.PerfilPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'carrinho',
    loadChildren: () => import('./pages/carrinho-page/carrinho-page.module').then(m => m.CarrinhoPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'resumo',
    loadChildren: () => import('./pages/resumo-precos-page/resumo-precos-page.module').then(m => m.ResumoPrecosPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'resumo-orcamento/:numeroOrcamento',
    loadChildren: () => import('./pages/resumo-orcamento/resumo-orcamento.module').then(m => m.ResumoOrcamentoModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'condicoes-venda',
    loadChildren: () => import('./pages/condicoes-venda-page/condicoes-venda-page.module').then(m => m.CondicoesVendaPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'pagina-nao-encontrada',
    loadChildren: () => import('./pages/not-found-page/not-found-page.module').then(m => m.NotFoundPageModule)
  },
  {
    path: '**',
    redirectTo: 'pagina-nao-encontrada'
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {})
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {
  public onActivate() {
    setTimeout(() => window.scrollTo(0, 0));
  }
}
