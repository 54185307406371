import { Injectable } from '@angular/core';types
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { StorageService } from './storage.service';
import { ConfigService } from './config.service';
import { TipoUtilizacao } from '../types/orcamento/tipo-utilizacao';
import { DadosCalculo } from '../types/orcamento/dados-calculo';
import { PrecosCalculados } from '../types/orcamento/precos-calculados';
import { PaginaInfo } from '../types/paginacao/pagina-info';
import { Orcamento } from '../types/orcamento/orcamento';
import { ListaOrcamentos } from '../types/orcamento/lista-orcamentos';
import { OrcamentoConversao } from '../types/orcamento/orcamento-conversao';
import { OrcamentoEnvioVendedor } from '../types/orcamento/orcamento-envio-vendedor';
import { OrcamentoEnvioPerdido } from '../types/orcamento/orcamento-envio-perdido';
import { OrcamentoEnvioRevisao } from '../types/orcamento/orcamento-envio-revisao';
import { types } from 'util';

export interface OrcamentoFiltro {
  DataInicial: Date;
  DataFinal: Date;
  NumeroOrcamento: string;
  IdSituacaoOrcamento: number;
  PaginaInfo: PaginaInfo;
}

@Injectable()
export class OrcamentoService {

  static storageDadosCalculo = 'DadosCalculo';
  static storageOrcamentoRevisao = 'OrcamentoRevisao';

  constructor(
    private http: HttpClient,
    private storage: StorageService,
    private config: ConfigService
  ) { }

  listarTiposUtilizacao(idCliente: number): Observable<TipoUtilizacao[]> {
    let params = new HttpParams();
    
    params = params.set('idCliente', idCliente.toString());

    return this.http.get(`${environment.api}/Orcamentos/ObterTiposUtilizacaoParaClienteLogado`, { params: params })
      .pipe(map((res: TipoUtilizacao[]) => res));
  }

  obterDadosCalculo(idCliente: number): Observable<DadosCalculo> {
    let params = new HttpParams();

    params = params.set('idCliente', idCliente.toString());

    return this.http.get(`${environment.api}/Orcamentos/ObterDadosCalculoOrcamento`, { params: params })
      .pipe(map((res: DadosCalculo) => res));
  }

  calcularPrecos(dadosCalculo: DadosCalculo): Observable<PrecosCalculados> {
    let params = new HttpParams();

    params = params.set('idCliente', this.config.dadosCalculo.IdCliente.toString());

    dadosCalculo.ItensCarrinho.forEach(item => {
      delete item.Produto.CodigoProduto;
      delete item.Produto.Descricao;
      delete item.Produto.Imagem;
      delete item.Produto.Categoria;
      delete item.Produto.SubCategoria;
    });

    dadosCalculo.TipoUtilizacao = this.dadosCalculo.TipoUtilizacao;

    return this.http.post(`${environment.api}/Orcamentos/CalcularPrecosCarrinho`, dadosCalculo, { params: params })
      .pipe(map((res: PrecosCalculados) => res));
  }

  gerarOrcamento(dadosCalculo: DadosCalculo): Observable<any> {
    let params = new HttpParams();

    params = params.set('idCliente', this.config.dadosCalculo.IdCliente.toString());

    dadosCalculo.ItensCarrinho.forEach(item => {
      delete item.Produto.CodigoProduto;
      delete item.Produto.Descricao;
      delete item.Produto.Imagem;
      delete item.Produto.Categoria;
      delete item.Produto.SubCategoria;
    });
    let numeroOrcamento = '';
    let numeroRevisao = 0;
    const orcamentoRevisao = this.orcamentoRevisao;
    if (/^[0-9]+$/.test(orcamentoRevisao.NumeroOrcamento || '')) {
      numeroOrcamento = orcamentoRevisao.NumeroOrcamento;
      numeroRevisao = orcamentoRevisao.NumeroRevisao;
    }
    dadosCalculo.NumeroOrcamento = numeroOrcamento;
    dadosCalculo.NumeroRevisao = numeroRevisao;
    dadosCalculo.TipoUtilizacao = this.dadosCalculo.TipoUtilizacao;

    return this.http.post(`${environment.api}/Orcamentos/GerarOrcamento`, dadosCalculo, { params: params })
      .pipe(map((res: string) => {
        this.limparOrcamentoEmRevisao();
        return res;
      }));
  }

  pesquisarOrcamentos(filtro: OrcamentoFiltro): Observable<ListaOrcamentos> {
    let params = new HttpParams();

    params = params.set('idCliente', this.config.dadosCalculo.IdCliente.toString());
    params = params.set('pagina', filtro.PaginaInfo.Pagina.toString());
    params = params.set('linhasPorPagina', filtro.PaginaInfo.LinhasPorPagina.toString());

    if (types.isDate(filtro.DataInicial) && types.isDate(filtro.DataFinal)) {
      params = params.set('dataInicial', filtro.DataInicial.toDateString());
      params = params.set('dataFinal', filtro.DataFinal.toDateString());
    } else {
      params = params.set('dataInicial', '');
      params = params.set('dataFinal', '');
    }

    params = params.set('numeroOrcamento', filtro.NumeroOrcamento || '');
    params = params.set('idSituacaoOrcamento', filtro.IdSituacaoOrcamento > 0 ? filtro.IdSituacaoOrcamento.toString() : '');

    return this.http.get(`${environment.api}/Orcamentos/ObterOrcamentosParaClienteLogado`, { params: params })
      .pipe(map((res: ListaOrcamentos) => res));
  }

  exportarOrcamento(id: number): Observable<Blob> {
    let params = new HttpParams();

    params = params.set('idCliente', this.config.dadosCalculo.IdCliente.toString());
    params = params.set('idOrcamento', id.toString());

    return this.http.get(`${environment.api}/Orcamentos/ExportarOrcamento`, { params, responseType: 'blob' });
  }

  converterOrcamento(orcamentoConversao: OrcamentoConversao) {
    let params = new HttpParams();

    params = params.set('idCliente', this.config.dadosCalculo.IdCliente.toString());

    return this.http.put(`${environment.api}/Orcamentos/ConverterOrcamento`, orcamentoConversao, { params: params });
  }

  obterProdutosOrcamentoPorId(id) {
    let params = new HttpParams();

    params = params.set('idCliente', this.config.dadosCalculo.IdCliente.toString());
    params = params.set('numeroOrcamento', id);

    return this.http.get(`${environment.api}/Orcamentos/ObterResumo`, { params: params })
      .pipe(map((res) => res));
  }

  get dadosCalculo(): DadosCalculo {
    return this.config.dadosCalculo;
  }

  set dadosCalculo(value: DadosCalculo) {
    this.config.dadosCalculo = value;
  }

  enviarAoVendedor(orcamentoEnvioVendedor: OrcamentoEnvioVendedor) {
    let params = new HttpParams();

    params = params.set('idCliente', this.config.dadosCalculo.IdCliente.toString());

    return this.http.put(`${environment.api}/Orcamentos/EnviarOrcamentoAoVendedor`, orcamentoEnvioVendedor, { params: params });
  }

  enviarPerdido(orcamentoEnvioPerdido: OrcamentoEnvioPerdido) {
    let params = new HttpParams();

    params = params.set('idCliente', this.config.dadosCalculo.IdCliente.toString());

    return this.http.put(`${environment.api}/Orcamentos/EnviarOrcamentoPerdido`, orcamentoEnvioPerdido, { params: params });
  }

  enviarRevisao(orcamentoEnvioRevisao: OrcamentoEnvioRevisao): Observable<OrcamentoEnvioRevisao> {
    let params = new HttpParams();

    params = params.set('idCliente', this.config.dadosCalculo.IdCliente.toString());

    return this.http.put(`${environment.api}/Orcamentos/EnviarOrcamentoRevisao`, orcamentoEnvioRevisao, { params: params })
      .pipe(map((res: OrcamentoEnvioRevisao) => res));
  }

  get orcamentoRevisao(): Orcamento {
    return this.storage.getStorage(OrcamentoService.storageOrcamentoRevisao, 'localStorage') || <Orcamento>{};
  }

  set orcamentoRevisao(value: Orcamento) {
    const save = <Orcamento>{ ...value };
    this.storage.setStorage<Orcamento>(OrcamentoService.storageOrcamentoRevisao, save, 'localStorage');
  }

  limparOrcamentoEmRevisao() {
    this.orcamentoRevisao = <Orcamento>{};
  }

}
