import { Injectable } from '@angular/core';

import { StorageService } from './storage.service';
import { Agrupamento } from '../types/agrupamento/agrupamento';

@Injectable()
export class AgrupamentoService {

  static storage = 'Agrupamento';

  constructor(
    private storage: StorageService
  ) { }

  limpar(){
    this.agrupamentos = [];
  }

  get agrupamentos(): Agrupamento[] {
    return this.storage.getStorage(AgrupamentoService.storage, 'localStorage') || <Agrupamento[]>{};
  }

  set agrupamentos(value: Agrupamento[]) {
    const save = <Agrupamento[]>[ ...value ];
    this.storage.setStorage<Agrupamento[]>(AgrupamentoService.storage, save, 'localStorage');
  }

}
