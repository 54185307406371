import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { Contrato } from '../types/contrato/contrato';

@Injectable()
export class ContratoService {

  constructor(
    private http: HttpClient
  ) {}

  listar(idCliente: number): Observable<Contrato[]> {
    let params = new HttpParams();

    params = params.set('idCliente', idCliente.toString());

    return this.http.get(`${environment.api}/Contratos/ObterContratosAtivosParaClienteLogado`, { params: params })
      .pipe(map((res: Contrato[]) => res));
  }

}
