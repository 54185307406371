import { Injectable } from '@angular/core';

@Injectable()
export class ConteudoService {

  static projeto = 'Probid Cliente';

  constructor() {}

  titulo(nomePagina: string) {
    return nomePagina + ' - ' + ConteudoService.projeto;
  }

}
