import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';

import { AuthorizationService } from '../services/authorization.service';

@Injectable()
export class HasAuthGuard implements CanActivate {
  constructor(
    private authz: AuthorizationService,
    private router: Router
  ) {
  }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (this.authz.token) {
      this.router.navigate(['home']).then();
      return false;
    } else {
      return true;
    }
  }
}
