import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import swal from 'sweetalert2';

@Injectable()
export class ErrorHandlerService {

  constructor(
  ) { }

  handle(errorResponse: any) {
    let msg: string;

    if (typeof errorResponse === 'string') {
      msg = errorResponse;
    } else if (errorResponse instanceof HttpErrorResponse
      && errorResponse.status >= 400 && errorResponse.status <= 499) {
        msg = 'Ocorreu um erro ao processar a sua solicitação.';

        if (errorResponse.status === 400) {
          msg = errorResponse.error.Message || errorResponse.error;
        }

        if (errorResponse.status === 401) {
          msg = 'Você não tem permissão para executar esta ação.';
        }

        if (errorResponse.status === 404) {
          msg = 'A informação que você busca não foi encontrada.';
        }

        console.error('Ocorreu um erro', errorResponse);
    } else {
      msg = 'Erro ao processar serviço remoto. Tente novamente.';
      console.error('Ocorreu um erro', errorResponse);
    }

    swal.fire({
      title: 'Aviso!',
      text: msg,
      type: 'warning'
    }).then();
  }

}
